import { ChevronRightIcon, PlusIcon } from "@heroicons/react/solid";
import React from "react";
import Layout from "../components/Layout/layout";
import { Link } from "gatsby";

const AboutUs = () => {
  return (
    <>
      <Layout>
        <div className="py-20 bg-gray-100">
          <div className="mx-auto md:max-w-screen-md lg:max-w-screen-lg">
            <div>
              <h1 className="text-3xl md:text-4xl lg:text-5xl md:leading-relaxed lg:leading-relaxed text-center leading-relaxed text-gray-600">
                Designing Eco-Friendly <br /> Mobility Solutions for <br />{" "}
                <span className="font-semibold text-5xl md:text-6xl lg:text-7xl bg-gradient-to-r from-blue-500 to-blue-700 text-transparent bg-clip-text">
                  Everyone
                </span>
              </h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 mt-20 gap-2 md:gap-4 mx-4 md:mx-4 lg:mx-auto">
              <div
                className="bg-gray-50 rounded-lg h-[640px] shadow md:shadow-lg p-6 col-span-2 md:col-span-1 bg-cover bg-bottom"
                style={{
                  backgroundImage: `url("https://images.prismic.io/kabiramobilitycms/47c4c884-0daa-41b4-8732-771f91575577_Story+-+01.png?auto=compress,format")`,
                }}
              >
                <div className="mt-12 flex flex-col items-center space-y-6">
                  <h2 className="text-4xl text-center text-gray-50 font-regular leading-relaxed">
                    The Story of <br />{" "}
                    <span className="font-semibold">Kabira Mobility</span>
                  </h2>
                  <Link to = "/coming-soon">
                  <span className="group ">
                    <button className="py-3 px-6 rounded-full border-2 border-blue-700 bg-white  group-hover:bg-blue-700 group-hover:shadow flex flex-row space-x-2 items-center">
                      <span className="text-base font-semibold group-hover:text-white text-blue-700">
                        Learn More
                      </span>
                      <span>
                        <ChevronRightIcon className="h-5 w-5 text-blue-700 group-hover:text-white" />
                      </span>
                    </button>
                  </span>
                  </Link>
                </div>
              </div>
              <div
                className="bg-gray-50 rounded-lg h-[640px] shadow md:shadow-lg p-6 col-span-2 md:col-span-1 bg-cover bg-bottom"
                style={{
                  backgroundImage: `url("https://images.prismic.io/kabiramobilitycms/ce235558-042f-478d-9edf-159a6a9626b2_Team-3.png?auto=compress,format")`,
                }}
              >
                <div className="mt-12 flex flex-col items-center space-y-6">
                  <h2 className="text-4xl text-center text-gray-50 font-regular leading-relaxed">
                    The Team at <br />{" "}
                    <span className="font-semibold">Kabira Mobility</span>
                  </h2>
                  <Link to = "/coming-soon">
                  <span className="group ">
                    <button className="py-3 px-6 rounded-full border-2 border-blue-700 bg-white  group-hover:bg-blue-700 group-hover:shadow flex flex-row space-x-2 items-center">
                      <span className="text-base font-semibold group-hover:text-white text-blue-700">
                        Learn More
                      </span>
                      <span>
                        <ChevronRightIcon className="h-5 w-5 text-blue-700 group-hover:text-white" />
                      </span>
                    </button>
                  </span>
                  </Link>
                </div>
              </div>
              <div
                className="bg-gray-50 rounded-lg h-[640px] shadow md:shadow-lg p-6 col-span-2  bg-cover bg-bottom bg-no-repeat"
                style={{
                  backgroundImage: `url("https://images.prismic.io/kabiramobilitycms/2886637b-e542-406e-acdb-c9512cca0eeb_Environment.png?auto=compress,format")`,
                }}
              >
                <div className="mt-12 flex flex-col items-center space-y-6">
                  <h2 className="text-4xl text-center text-gray-50 font-regular leading-relaxed">
                    Environment and <br />{" "}
                    <span className="font-semibold">Kabira Mobility</span>
                  </h2>
                  <Link to = "/coming-soon">
                  <span className="group ">
                    <button className="py-3 px-6 rounded-full border-2 border-blue-700 bg-white  group-hover:bg-blue-700 group-hover:shadow flex flex-row space-x-2 items-center">
                      <span className="text-base font-semibold group-hover:text-white text-blue-700">
                        Learn More
                      </span>
                      <span>
                        <ChevronRightIcon className="h-5 w-5 text-blue-700 group-hover:text-white" />
                      </span>
                    </button>
                  </span>
                  </Link>
                </div>
              </div>
              <div
                className="bg-gray-50 rounded-lg h-[640px] shadow md:shadow-lg p-6 col-span-2 md:col-span-1 bg-cover bg-bottom"
                style={{
                  backgroundImage: `url("https://images.prismic.io/kabiramobilitycms/455358f3-ad47-4da0-8db7-ebe036a6d140_Careers.png?auto=compress,format")`,
                }}
              >
                <div className="mt-12 flex flex-col items-center space-y-6">
                  <h2 className="text-4xl text-center text-gray-50 font-regular leading-relaxed">
                    Careers at <br />{" "}
                    <span className="font-semibold">Kabira Mobility</span>
                  </h2>
                  <Link to = "/coming-soon">
                    <span className="group ">
                      <button className="py-3 px-6 rounded-full border-2 border-blue-700 bg-white  group-hover:bg-blue-700 group-hover:shadow flex flex-row space-x-2 items-center">
                        <span className="text-base font-semibold group-hover:text-white text-blue-700">
                          Join Us{" "}
                        </span>
                        <span>
                          <ChevronRightIcon className="h-5 w-5 text-blue-700 group-hover:text-white" />
                        </span>
                      </button>
                    </span>
                  </Link>
                </div>
              </div>
              <div
                className="bg-white rounded-lg h-[640px] shadow md:shadow-lg p-6 col-span-2 md:col-span-1 bg-cover bg-bottom"
                style={{
                  backgroundImage: `url("https://images.prismic.io/kabiramobilitycms/669ed9e2-857e-4bc9-956a-ca038e3d72f1_Contact+2.png?auto=compress,format")`,
                }}
              >
                <div className="mt-12 flex flex-col items-center space-y-6">
                  <h2 className="text-4xl text-center text-gray-700 font-regular leading-relaxed">
                    Contact Us at <br />{" "}
                    <span className="font-semibold">Kabira Mobility</span>
                  </h2>
                  <Link to = "/coming-soon">
                  <span className="group ">
                    <button className="py-3 px-6 rounded-full border-2 border-blue-700 bg-white  group-hover:bg-blue-700 group-hover:shadow flex flex-row space-x-2 items-center">
                      <span className="text-base font-semibold group-hover:text-white text-blue-700">
                        Contact Us
                      </span>
                      <span>
                        <ChevronRightIcon className="h-5 w-5 text-blue-700 group-hover:text-white" />
                      </span>
                    </button>
                  </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AboutUs;
